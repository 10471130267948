import { create } from "zustand"

type aspect = "N" | "E" | "S" | "W"
interface TAptObject  {
  unit: number,
  floor: number,
  area: number,
  baths: number,
  beds: number,
  price: number,
  aspect: aspect[]
  type: string,
  typeName: string,
  unitStatus: string,
  balcony: boolean,
  available: boolean
  yardiURL: string
}
interface InitialState {
  isInt: boolean
  //VIEWPORT
  viewport: any,
  setViewport: (v:any) => void,
  //CAMERA POSITIONS
  cameraSettings: any,
  setCameraSettings: (v: any) => void,
  //GENERAL
  setLocalState: (stateToUpdate: any, data: any) => void
  controlsCatcher: any
  setcontrolsCatcher: (v: any) => void
  panning: boolean
  setPanning: (v: boolean) => void
  isIpad: boolean
  setIsIpad: (v: boolean) => void
  sceneLoaded: boolean
  setSceneLoaded: (v: boolean) => void
  imagesLoaded: boolean
  setImagesLoaded: (v: boolean) => void
  //NAVIGATION
  navOpen: boolean
  setNavOpen: (v: boolean) => void
  navigating: boolean
  setNavigating: (v: boolean) => void
  //SPLASH
  introSlide: number
  setIntroSlide: (v: number) => void
  //GALLERY
  galleryPopup: boolean
  setGalleryPopup: (v: boolean) => void
  galleryPopupInfo: {[x: string]: any} | null
  setGalleryPopupInfo: (v: {[x: string]: any}) => void
  currentImage: number | null
  setCurrentImage: (v: number) => void
  currentImgVisible: boolean,
  setCurrentImgVisible: (v: boolean) => void
  //APARTMENT CARD
  fullUnits: null | TAptObject[]
  setFullUnits: (v: TAptObject[]) => void
  currentApt: TAptObject | null
  setCurrentApt: (v: any) => void
  cardOpen: boolean
  setCardOpen: (v: boolean) => void
  cardSectionIndex: number
  setCardSectionIndex: (v: number) => void
  //FAVORITES
  favorites: any[] | number[]
  setFavorites: (v: number[] | []) => void
  toggleFavorites: (v: any) => void
  sharePopup: boolean
  setSharePopup: (v: boolean) => void
  //AMENITIES
  currentAmenityIndex: number | null 
  setCurrentAmenityIndex: (v: number | null) => void
  amenityRenderOpen: boolean
  setAmenityRenderOpen: (v: boolean) => void
  gridView: boolean
  toggleGridView: (v: boolean) => void
  activeLevel: null
  setActiveLevel: (v: any) => void
  amenityModelVisible: boolean
  setAmenityModelVisible: (v: boolean) => void
  amenitySwitching: boolean
  setAmenitySwitching: (v: boolean) => void
  //RESIDENCES
  filteredUnits:  any[] | null
  setFilteredUnits: (v: any) => void
  showApartmentInfo: boolean
  setShowApartmentInfo: (v: boolean) => void
  showList: boolean
  setShowList: (v: boolean) => void
  filterOpen: boolean
  setFilterOpen: (v: boolean) => void
  //Filter
  //FILTER:
  priceRange: (null | number)[],
  minPrice: null | number,
  maxPrice: null | number,
  beds: number[],
  faces: ("N" | "E" | "S" | "W")[],
  levelRange: (null | number)[],
  minLevel: null | number,
  maxLevel: null | number,
  balcony:  boolean | null,
  setBalcony: (v: boolean | null) => void,
  offlineUnits: boolean
}

export const useStore = create<InitialState>()((set, get) => ({
  isInt: window.location.host.includes("web"),
  // isInt: true,
  viewport:{w:0, h:0},
  setViewport: (v)=>  set({viewport: v}),
  //CAMERA POSITIONS
  cameraSettings: {},
  setCameraSettings: v => set({ cameraSettings: v }),
  //GENERAL
  setLocalState: (stateToUpdate: any, data: any) => {
      set({ [stateToUpdate]: data })
  },
  controlsCatcher: null,
  setcontrolsCatcher: (v) => set({ controlsCatcher: v }),
  panning: false,
  setPanning: (v) => set({ panning: v }),
  sceneLoaded: false,
  setSceneLoaded: (v) => set({sceneLoaded: v}),
  isIpad:
      (/Macintosh/i.test(navigator.userAgent) && innerWidth === 1366) ||
      innerWidth === 1575,
  setIsIpad: (v) => set({ isIpad: v }),
  imagesLoaded: false,
  setImagesLoaded: (v) => set({imagesLoaded: v}),
  //NAVIGATION
  navOpen: false,
  setNavOpen: (v) => set({navOpen: v}),
  navigating: true,
  setNavigating: (v) => set({navigating: v}),
  //SPLASH
  introSlide: 0,
  setIntroSlide: (v) => set({introSlide: v}),
  //GALLERY
  galleryPopup: false,
  setGalleryPopup: (v) => set({galleryPopup: v}),
  galleryPopupInfo: null,
  setGalleryPopupInfo: (v) => set({galleryPopupInfo: v}),
  currentImage: null,
  setCurrentImage: (v) => set({ currentImage: v }),
  currentImgVisible: true,
  setCurrentImgVisible: (v) => set({ currentImgVisible: v }),
  //APARTMENT CARD
  fullUnits: null,
  setFullUnits: (v) => set({fullUnits: v}),
  currentApt: null,
  setCurrentApt: (v) => set({currentApt: v}),
  cardOpen: false,
  setCardOpen: (v) => set({cardOpen: v}),
  cardSectionIndex: 0,
  setCardSectionIndex: (v) => set({cardSectionIndex: v}),
  //FAVORITES
  favorites:  [],
  setFavorites: (v) => set({favorites: v}),
  toggleFavorites: (v) =>
      set((state) => ({
        favorites: state.favorites.some((obj: TAptObject) => obj.unit === v.unit)
          ? state.favorites.filter((fav: TAptObject) => fav.unit !== v.unit)
          : [...state.favorites, v],
      })),
  sharePopup: false,
  setSharePopup: (v) => set({sharePopup: v}),
  //AMENITIES
  currentAmenityIndex: null,
  setCurrentAmenityIndex: v => set({ currentAmenityIndex: v }),
  amenityRenderOpen: false,
  setAmenityRenderOpen: (v) => set({amenityRenderOpen: v}),
  gridView: false,
  toggleGridView: (v) => set({gridView: v}),
  activeLevel: null,
  setActiveLevel: (v) => set({activeLevel: v}),
  amenityModelVisible: false,
  setAmenityModelVisible: (v) => set({amenityModelVisible: v}),
  amenitySwitching: false,
  setAmenitySwitching: (v) => set({amenitySwitching: v}),
  //RESIDENCES
  filteredUnits: null,
  setFilteredUnits: (v) => set({filteredUnits: v}),
  showApartmentInfo: false,
  setShowApartmentInfo: (v) => set({showApartmentInfo: v}),
  showList: false,
  setShowList: (v) => set({showList: v}),favourites: [],
  filterOpen: false,
  setFilterOpen: (v) => set({filterOpen: v}),
  //FILTER:
  priceRange: [null, null],
  minPrice: null,
  maxPrice: null,
  beds: [],
  faces: [],
  levelRange: [null, null],
  minLevel: null,
  maxLevel: null,
  balcony: null,
  setBalcony: (v) => set({balcony: v}),
  offlineUnits: false
}))
