import { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import gsap from "gsap"

import CloseButton from "@components/CloseButton"
import TextFrame from "@components/TextFrame/TextFrame"

import galleryData from "@data/galleryData"
import globalStyles from "@data/globalStyles"
import { useStore } from "@state/store"
import ArrowIcon from "@components/SVGIcons/ArrowIcon"

function GalleryPopup({ durationMS }) {
	const bgRef = useRef<HTMLDivElement>()
	const imageRef = useRef<HTMLImageElement>()
	const animated = useRef<boolean>(false)
	const galleryPopup = useStore(s => s.galleryPopup)
	const setGalleryPopup = useStore(s => s.setGalleryPopup)
	const setGalleryPopupInfo = useStore(s => s.setGalleryPopupInfo)
	const setCurrentImage = useStore(s => s.setCurrentImage)
	const setCurrentImgVisible = useStore(s => s.setCurrentImgVisible)
	const galleryPopupInfo = useStore(s => s.galleryPopupInfo)
	const currentImage = useStore(s => s.currentImage)
	const galleryDataArr = galleryData.flat()

	function handleIndexChange(e,dir){
		e.stopPropagation()
		imageRef.current.onload = null
		const min = 0
		const max = galleryDataArr.length - 1
		const nextIndex = dir ? (currentImage + 1 > max ? min : currentImage + 1) : (currentImage - 1 < min ? max : currentImage - 1)
		setCurrentImage(nextIndex)
		const target = document.getElementById(`gallImg${nextIndex}`)
		const rect =  target.getBoundingClientRect()
		setGalleryPopupInfo({
			width: rect.width,
			height: rect.height,
			top: rect.y,
			left: rect.x
		})
		setTimeout(() => {
			imageRef.current.src = galleryDataArr[nextIndex].src
		}, 10)
	}

	
	function handleLoad(x) {
		if (!animated.current) {
			const keyframes = [
				{
					width: `${galleryPopupInfo.width}px`,
					height: `${galleryPopupInfo.height}px`,
					top: `${galleryPopupInfo.top}px`,
					left: `${galleryPopupInfo.left}px`,
					filter: "saturate(0%)"
				},
				{
					width: `${innerWidth * 0.8}px`,
					height: `${innerHeight * 0.8}px`,
					top: `${innerHeight / 2 - (innerHeight * 0.8) / 2}px`,
					left: `${innerWidth / 2 - (innerWidth * 0.8) / 2}px`,
					filter: "saturate(100%)"
				}
			]
			const animation = imageRef.current.animate(keyframes, {
				duration: durationMS * 0.6,
				easing: "ease-in-out",
				fill: "forwards",
				direction: x ? "normal" : "reverse",
				delay: x ? durationMS * 0.6 : 0
			})
			animation.onfinish = () => handleAnimationEnd(x)
		}
	}

	function handleAnimationEnd(x) {
		animated.current = true
		setTimeout(() => {
			imageRef.current.src = galleryDataArr[currentImage].src
		}, 10)
	}


	return (
		galleryPopup && (
			<GalleryPopupWrapper>
				<Background
					style={{
						transitionDuration: `${durationMS * 0.6}ms`
					}}
					ref={node => {
						if (node && !bgRef.current) {
							node.style.transitionDelay = "0ms"
							setTimeout(() => (node.style.opacity = "1"), durationMS * 0.1)
							bgRef.current = node
						}
					}}>
					<CloseButton
						handleClose={() => {
							setCurrentImgVisible(true)
							animated.current = false
							bgRef.current.style.transitionDelay = `${durationMS * 0.4}ms`
							bgRef.current.style.opacity = "0"
							handleLoad(false)
							setTimeout(() => {
								animated.current = false
								setCurrentImage(null)
								setGalleryPopup(false)
								bgRef.current = null
							}, durationMS)
						}}
					/>
					<Caption>
						<TextFrame
							rightText={galleryDataArr[currentImage]?.caption[1]}
							bottomText={galleryDataArr[currentImage]?.caption[0]}
						/>
					</Caption>
					<Arrows className="centerABS">
					<ArrowIcon
              dir="left"
              strokeColor={globalStyles.colors.secondary}
              bgColor="transparent"
              strokeWidth={7}
							onClick={(e) => { handleIndexChange(e,false) }}
					/>
					<ArrowIcon
              dir="right"
              strokeColor={globalStyles.colors.secondary}
              bgColor="transparent"
              strokeWidth={7}
							onClick={(e) => { handleIndexChange(e,true) }}
					/>
				</Arrows>
				</Background>
				<img
					alt=""
					ref={imageRef}
					src={galleryDataArr[currentImage].thumbnail}
					onLoad={() => {
						handleLoad(true)
					}}
					style={{
						width: `${galleryPopupInfo.width}px`,
						height: `${galleryPopupInfo.height}px`,
						top: `${galleryPopupInfo.top}px`,
						left: `${galleryPopupInfo.left}px`
					}}
				/>
			</GalleryPopupWrapper>
		)
	)
}

export default GalleryPopup

const GalleryPopupWrapper = styled.div`
	position: fixed;
	top: 0;
	z-index: 10;
	width: 100%;
	height: 100%;
	opacity: 1;
	pointer-events: none;

	& img {
		display: block;
		position: relative;
		top: 50%;
		left: 50%;
		object-fit: contain;
		filter: saturate(0%);
	}
`

const Background = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.9);
	transition-property: opacity;
	transition-timing-function: ease-in-out;
	opacity: 0;
`

const Caption = styled.div`
	position: absolute;
	width: 22rem;
	z-index: 1;
	bottom: 2rem;
	right: 2rem;
	color: ${globalStyles.colors.secondary};
	font-family: title;
	overflow: hidden;

	& svg text {
		fill: blue;
	}
`

const Arrows = styled.div`
	width: 100%;
	height: clamp(20px, 5rem, 100px);
	z-index: 10;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	& svg{
		pointer-events: all;
	}
`
