import { useEffect } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import gsap from "gsap"

import DefaultLayout from "./DefaultLayout"
import Navigation from "@components/Navigation"
import SurfScene from "@components/SurfScene"

import { useStore } from "@state/store"
import backUpData from "@data/temp/fullUnitData"
import trimAPIData from "@utils/trimAPIData"
import ControlsCatcher from "@components/ControlsCatcher"
import LandScapePrompt from "@components/LandscapePrompt/LandScapePrompt"
import Residences from "@pages/Residences"

import styled from "styled-components"

gsap.config({
	nullTargetWarn: false
})

const showBalc = ["C06P", "C05P", "C04P", "C03P", "A027P"]


function App() {
	const fullUnits = useStore(s => s.fullUnits)
	const offlineUnits = useStore(s => s.offlineUnits)
	const setFullUnits = useStore(s => s.setFullUnits)
	const setLocalState = useStore(s => s.setLocalState)



	useEffect(() => {
		if (fullUnits === null) {
			fetch(`https://api-gateway.vmiservers.com/surf/units`)
				.then(res => res.json())
				.then(res => {
					const trimmed = trimAPIData(res.payload)
					localStorage.setItem("1515SurfUnits", JSON.stringify(trimmed))
					setFullUnits(trimmed)
				})
				.catch(error => {
					const stored = localStorage.getItem("1515SurfUnits") || null
					setFullUnits(stored ? JSON.parse(stored) : trimAPIData(backUpData))
					console.error(
						"There has been a problem with your fetch operation:",
						error
					)
				})
		}
	}, [])

	useEffect(() => {
		if (!offlineUnits) {
			const stored = localStorage.getItem("1515SurfUnits") || null
			setFullUnits(stored ? JSON.parse(stored) : trimAPIData(backUpData))
		} else {
			setFullUnits(trimAPIData(backUpData))
		}
	}, [offlineUnits])

	useEffect(() => {
		if (fullUnits) {
			let minPrice = Infinity
			let maxPrice = 0
			let minLevel = Infinity
			let maxLevel = 2
			for (let unit of fullUnits) {
				if (unit.available) {
					if (unit.price < minPrice) minPrice = unit.price
					if (unit.price > maxPrice) maxPrice = unit.price
					if (unit.floor < minLevel) minLevel = unit.floor
					if (unit.floor > maxLevel) maxLevel = unit.floor
				}
			}
			setLocalState("minPrice", minPrice)
			setLocalState("maxPrice", maxPrice)
			setLocalState("priceRange", [minPrice, maxPrice])
			setLocalState("minLevel", minLevel)
			setLocalState("maxLevel", maxLevel)
			setLocalState("levelRange", [minLevel, maxLevel])
		}
	}, [fullUnits])

	const isInt = useStore(s => s.isInt)

	return (
		<BrowserRouter>
			{isInt ? (
				<>
					<ContentWrapper>
						<Residences />
					</ContentWrapper>
					<ControlsCatcher />
					<SurfScene />
				</>
			) : (
				<>
					<SurfScene />
					<Routes>
						<Route path="*" element={<DefaultLayout />} />
					</Routes>
					<ControlsCatcher />
					<Navigation />
					<LandScapePrompt />
				</>
			)}
		</BrowserRouter>
	)
}

export default App

const ContentWrapper = styled.div`
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	pointer-events: none;
`
