import { useRef, useEffect, useState, Fragment } from "react"
import styled from "styled-components"

import GalleryPopup from "@components/GalleryPopup"

import galleryData from "@data/galleryData"
import { useStore } from "@state/store"
import useDragScroll from "@lib/useDragScroll"
import globalStyles from "@data/globalStyles"

function Gallery({ display }) {
	const panning = useStore(s => s.panning)
	const navigating = useStore(s => s.navigating)
	const currentImgVisible = useStore(s => s.currentImgVisible)
	const currentImage = useStore(s => s.currentImage)
	const galleryPopup = useStore(s => s.galleryPopup)
	const setLocalState = useStore(s => s.setLocalState)
	const xDownPos = useRef(null)
	const anmDur = 1000
	const scrollConfig = {
		damping: 25,
		onDown: (e) => {
			if(e.target.nodeName !== "IMG"){
				setLocalState("panning", true)
			}
		},
		onUp: (e) => {
			if(e.target.nodeName !== "IMG"){
				setLocalState("panning", false)
			}
		}
	}
	const { scrollerRef, handlePointerDown, handlePointerUp } =
		useDragScroll(scrollConfig)

	function handleImageClick(x: HTMLImageElement, i) {
		const rect = x.getBoundingClientRect()
		setLocalState("galleryPopup", true)
		setLocalState("galleryPopupInfo", {
			width: rect.width,
			height: rect.height,
			top: rect.y,
			left: rect.x
		})
		setLocalState("currentImage", i)
	}

	function handleImgPointerDown(e) {
		xDownPos.current = e.clientX
	}

	function handleImgPointerUp(e, n) {
		const diff = Math.abs(e.clientX - xDownPos.current)
		if (diff <= 5) {
			handleImageClick(e.currentTarget, n)
			setLocalState("currentImgVisible", false)
		}
	}

	useEffect(() => {
		if (scrollerRef.current) {
			scrollerRef.current.addEventListener("wheel", e => e.preventDefault(), {
				passive: false
			})
		}
		return () => {
			setLocalState("currentImage", null)
			setLocalState("galleryPopupInfo", null)
			setLocalState("galleryPopup", false)
		}
	}, [])
	return (
		<Fragment>
			<GalleryWrapper
				ref={scrollerRef}
				onPointerDown={handlePointerDown}
				onPointerUp={handlePointerUp}
				style={{
					opacity: panning ? 0.4 : display ? 1 : 0,
					transitionDuration: !panning ? "2s" : "0.4s",
					touchAction: "pan-x",
					pointerEvents: galleryPopup ? "none" : "all"
				}}>
				{galleryData.map((arr, index) => (
					<ImageWrapper key={index}>
						{arr.map((obj, i) => {
							return (
								obj && (
									<img
										alt=""
										id={`gallImg${obj.index}`}
										key={`ìmg${i}`}
										src={obj.thumbnail}
										style={{
											left: `${obj.position[0]}%`,
											top: `${obj.position[1]}%`,
											transform: `translate(-50%, -50%) scale(${obj.scale})`,
											opacity:
												currentImage === obj.index && !currentImgVisible
													? 0
													: 1,
											transitionDuration: `${anmDur * 0.6}ms`,
											transitionDelay: `${anmDur * 0.6}ms`,
											filter: "saturate(0%)"
										}}
										onPointerDown={handleImgPointerDown}
										onPointerUp={e => handleImgPointerUp(e, obj.index)}
									/>
								)
							)
						})}
					</ImageWrapper>
				))}
			</GalleryWrapper>
			<GalleryPopup durationMS={anmDur * 1.5} />
		</Fragment>
	)
}

export default Gallery

const GalleryWrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	transition-property: opacity;
	transition-timing-function: ease-in-out;
	opacity: 0;

	::-webkit-scrollbar {
		display: none;
	}
`

const ImageWrapper = styled.div`
	position: relative;
	height: 100%;
	display: inline-block;
	padding: 1rem;

	& img {
		max-height: 45%;
		cursor: pointer;
		display: block;
		position: relative;
		transition-property: opacity;
		opacity: 0;
		box-shadow: 0 0 3rem ${globalStyles.colors.black}44;
	}
`
