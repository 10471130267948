import styled from "styled-components"

import CloseIcon from "@components/SVGIcons/CloseIcon"

import globalStyles from "@data/globalStyles"

function CloseButton({ handleClose, color = undefined }) {
	return (
		<Close onClick={handleClose}>
			<CloseIcon strokeWidth={5} strokeColor={color ? color: globalStyles.colors.white} />
		</Close>
	)
}

export default CloseButton

const Close = styled.div`
	position: absolute;
	z-index: 1;
	width: 2rem;
	aspect-ratio: 1/1;
	cursor: pointer;
	top: 2rem;
	right: 2rem;
	display: grid;
	place-content: center;
	border-radius: 100%;
	pointer-events: all;
`
