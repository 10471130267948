import Splash from "@pages/Splash"
import Story from "@pages/Story"
import Surf from "@pages/Surf"
import Coney from "@pages/Coney"
import Residences from "@pages/Residences"
import Amenities from "@pages/Amenities"
import Favorites from "@pages/Favorites"
import ErrorMessage from "@pages/ErrorMessage"

const routes = [
	{
		name: "HOME",
		path: "/",
		onNav: true,
		element: Splash
	},
	{
		name: "STORY",
		path: "/story",
		surfName: `\u205f- STORY - STORY - STORY - STORY - STORY -`,
		onNav: true,
		onSurf: true,
		position: [0.5, 0.5, -1],
		polePosition: [0.5, 0.05, -1],
		element: Story
	},
	{
		name: "1515 SURF",
		path: "/surf",
		onNav: true,
		element: Surf
	},
	{
		name: "CONEY ISLAND",
		path: "/coney",
		onNav: true,
		onSurf: true,
		surfName: `\u205f- LOCAL AREA - LOCAL AREA - LOCAL AREA`,
		position: [0.5, 0.5, 1],
		polePosition: [0.5, 0.05, 1],
		element: Coney
	},
	{
		name: "RESIDENCES",
		path: "/residences",
		onNav: true,
		onSurf: true,
		surfName: `\u205f-\u205f 1515 SURF \u205f-\u205f\u205f 1515 SURF \u205f\u205f- 1515 SURF`,
		position: [0, 0.7, 0],
		polePosition: [0, 0.18, 0],
		element: Residences
	},
	{
		name: "AMENITIES",
		path: "/amenities",
		onNav: true,
		element: Amenities
	},
	{
		name: "FAVORITES",
		path: "/favorites",
		onNav: true,
		element: Favorites
	},
	{ path: "/*", name: "Not Found", element: ErrorMessage }
]

export default routes
