import styled from "styled-components"
import { Canvas } from "@react-three/fiber"
import * as THREE from "three"
import { useStore } from "@state/store"
import { Suspense, useEffect, useLayoutEffect, useState, useRef } from "react"
import { useLocation } from "react-router-dom"

import OrbitControlsCustom from "@canvas_components/Controls/OrbitControlsCustom"
import Building from "@canvas_components/Building/Building"
import AmenityModels from "@canvas_components/AmenitiesModels"

import globalStyles from "@data/globalStyles"
import HandleLoadingProgress from "@lib/loading"
import Coney from "@canvas_components/Coney/Coney"
import { useProgress } from "@react-three/drei"
import Loading from "@components/Loading"

function SurfScene() {
	const controlsCatcher = useStore(s => s.controlsCatcher)
	const { pathname } = useLocation()
	const { progress } = useProgress()
	const [bgColor, setBgColor] = useState<string>("#BABABA")
	const sceneLoaded = useStore(s => s.sceneLoaded)
	const setSceneLoaded = useStore(s => s.setSceneLoaded)

	useLayoutEffect(() => {
		setBgColor(pathname === "/amenities" ? "#BABABA" : globalStyles.colors.main)
	})

	const imagesLoaded = useStore(s => s.imagesLoaded)

	const [fontsLoaded, setFontsLoaded] = useState<boolean>(false)

	useEffect(() => {
		const preloadFonts = (fontUrls: string[]) => {
			const loadFont = (fontUrl: string) => {
				const fontFace = new FontFace("customFont", `url(${fontUrl})`)

				return fontFace.load()
			}

			const fontPromises = fontUrls.map((fontUrl: string) => {
				return loadFont(fontUrl)
			})

			Promise.all(fontPromises)
				.then(() => {
					setFontsLoaded(true)
				})
				.catch(error => {
					console.error("Fonts failed to load:", error)
				})
		}
		// make sure fonts are right for deploy
		const fontUrlsToPreload: string[] = [
			"/fonts/mono/Mono45Headline-Regular.woff",
			"/fonts/mono/Mono45Headline-Regular.woff2"
		]

		preloadFonts(fontUrlsToPreload)
	}, [])
	const [readyToLoad, setReadyToLoad] = useState<boolean>(false)

	useEffect(() => {
		if (pathname === "/" && imagesLoaded && fontsLoaded) {
			setReadyToLoad(true)
		} else {
			setReadyToLoad(true)
		}
	}, [])

	const showLoadingComponent =
		pathname === "/residences" ||
		pathname === "/amenities" ||
		pathname === "/surf"
	return (
		<>
			{readyToLoad && (
				<>
					<World
						onCreated={state => {
							state.events.connect(controlsCatcher)
						}}
						camera={{
							fov: 55,
							near: 0.01,
							position: new THREE.Vector3(
								-5.994564858075958,
								0.6976831814830297,
								-2.8280573179870045
							)
						}}>
						<color attach="background" args={[bgColor]} />
						{/* <color attach="background" args={[bgColor]} /> */}
						<OrbitControlsCustom
							makeDefault
							enablePan={pathname !== "/surf"}
							enableRotate={pathname !== "/surf"}
							enableZoom={pathname !== "/surf"}
						/>

						<HandleLoadingProgress
							totalAssets={187}
							//@ts-ignore
							// logassets={import.meta.env.DEV ?? false}
							logassets={false}
							onLoaded={() => {
								setTimeout(() => setSceneLoaded(true), 1000)
							}}
						/>

						<Suspense fallback={null}>
							<Coney />
							<Building />
							<AmenityModels />
						</Suspense>
					</World>
					{showLoadingComponent && <Loading cue={!sceneLoaded} />}
				</>
			)}
		</>
	)
}

export default SurfScene

const World = styled(Canvas)`
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
`
