const galleryData = [
	[
		{
			src: "/images/gallery/exteriorLounge.webp",
			thumbnail: "/images/gallery/thumbnails/exteriorLounge.webp",
			index: 0,
      position: [50, 60],
			scale: 0.8,
			caption: ["LOUNGE", "EXTERIOR"]
		}
	],
	[
		{
			src: "/images/gallery/exteriorWheel.webp",
			thumbnail: "/images/gallery/thumbnails/exteriorWheel.webp",
			index: 1,
      position: [50, 20],
			scale: 0.8,
			caption: ["SURF", "VIEW"]
		},
		{
			src: "/images/gallery/parade.webp",
			thumbnail: "/images/gallery/thumbnails/parade.webp",
			index: 2,
      position: [50, 25],
			scale: 1,
			caption: ["SURF", "ALIVE"]
		}
	],
	[
    {
			src: "/images/gallery/exteriorDay.webp",
			thumbnail: "/images/gallery/thumbnails/exteriorDay.webp",
			index: 3,
      position: [50, 50],
			scale: 1,
			caption: ["SURF", "EXTERIOR"]
		},
		
	],
	[
		{
      src: "/images/gallery/pool.webp",
			thumbnail: "/images/gallery/thumbnails/pool.webp",
			index: 4,
      position: [50, 30],
			scale: 0.8,
			caption: ["SURF", "POOL"]
		},
		{
			src: "/images/gallery/exteriorNight.webp",
			thumbnail: "/images/gallery/thumbnails/exteriorNight.webp",
			index: 5,
      position: [50, 30],
			scale: 0.75,
			caption: ["SURF", "NIGHT"]
		}
	],
	[
		{
			src: "/images/gallery/lounge.webp",
			thumbnail: "/images/gallery/thumbnails/lounge.webp",
			index: 6,
      position: [50, 45],
			scale: 1,
			caption: ["OCEAN", "LOUNGE"]
		}
	],
	[
		{
			src: "/images/gallery/surfAvenue.webp",
			thumbnail: "/images/gallery/thumbnails/surfAvenue.webp",
			index: 7,
      position: [50, 25],
			scale: 0.7,
			caption: ["SURF", "AVENUE"]
		},
		{
			src: "/images/gallery/terrace.webp",
			thumbnail: "/images/gallery/thumbnails/terrace.webp",
			index: 8,
      position: [50, 20],
			scale: 0.9,
			caption: ["SURF", "TERRACE"]
		}
	],
	[
		{
			src: "/images/gallery/gym.webp",
			thumbnail: "/images/gallery/thumbnails/gym.webp",
			index: 9,
      position: [50, 50],
			scale: 1,
			caption: ["SURF", "GYM"]
		}
	],
	[
		{
			src: "/images/gallery/kitchenB1.webp",
			thumbnail: "/images/gallery/thumbnails/kitchenB1.webp",
			index: 10,
      position: [50, 25],
			scale: 1,
			caption: ["SURF", "KITCHEN"]
		},
		{
			src: "/images/gallery/balcony.webp",
			thumbnail: "/images/gallery/thumbnails/balcony.webp",
			index: 11,
      position: [50, 35],
			scale: 1,
			caption: ["SURF", "BALCONY"]
		}
	],
	[
		{
			src: "/images/gallery/kitchenB2.webp",
			thumbnail: "/images/gallery/thumbnails/kitchenB2.webp",
			index: 12,
      position: [50, 50],
			scale: 0.8,
			caption: ["SURF", "KITCHEN"]
		}
	]
]

export default galleryData
