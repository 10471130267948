import { useEffect, useState } from "react"
import styled from "styled-components"

import TextFrame from "@components/TextFrame/TextFrame"
import CubeIcon from "@components/SVGIcons/CubeIcon"
import PlanIcon from "@components/SVGIcons/PlanIcon"
import TelescopeIcon from "@components/SVGIcons/TelescopeIcon"
import ApartmentView from "@components/ApartmentView"
import FloorplanViewer from "@components/FloorplanViewer"
import Plan3DViewer from "@components/Plan3DViewer"
import TransitionDiv from "@components/TransitionDiv"
import { toggleArrayItem } from "@utils/functions"

import globalStyles from "@data/globalStyles"
import { useStore } from "@state/store"
import StarIcon from "@components/SVGIcons/StarIcon"
import YardiIcon from "@components/SVGIcons/YardiIcon"

function ApartmentCard() {
	const [show, setShow] = useState(false)
	const currentApt = useStore(s => s.currentApt)

	const cardSectionIndex = useStore(s => s.cardSectionIndex)
	const isInt = useStore(s => s.isInt)
	const setCardSectionIndex = useStore(s => s.setCardSectionIndex)
	const favorites = useStore(s => s.favorites)
	const setFavorites = useStore(s => s.setFavorites)
	const iconsArr = [CubeIcon, PlanIcon, TelescopeIcon]
	const contentArr = [
		<Plan3DViewer isInt={isInt} />,
		<FloorplanViewer isInt={isInt} />,
		<ApartmentView isInt={isInt} />
	]

	useEffect(() => {
		if (cardSectionIndex === 0 || cardSectionIndex === 2) {
			setShow(false)
			setTimeout(() => {
				setShow(true)
			}, 3000)
		}
	}, [cardSectionIndex])

	return (
		<CardWrapper>
			<Content>
				<TransitionDiv
					childrenArr={contentArr}
					triggerIndex={cardSectionIndex}
					durationMS={1000}
				/>
			</Content>
			<RightCorner>
				<TextFrame
					rightText={`LVL ${currentApt?.floor}`}
					bottomText={`APT ${currentApt?.unit}`}
					style={{
						position: "relative",
						width: "clamp(200px, 30rem, 500px)",
						height: "clamp(350px, 45rem, 700px)",
						fontSize: "clamp(10px, 2rem, 30px)",
						fill: globalStyles.colors.white
					}}
				/>
			</RightCorner>
			<LeftCorner>
				<Info
					style={{
						opacity: cardSectionIndex === 2 ? 0 : 1
					}}>
					<p>{`${currentApt?.beds} ${
						currentApt?.beds > 1 ? "BEDS" : "BED"
					}`}</p>
					<p>{`${currentApt?.baths} ${
						currentApt?.baths > 1 ? "BATHS" : "BATH"
					}`}</p>
					<p>{`${currentApt?.area} SQFT`}</p>
					<p>{`$ ${currentApt?.price}`}</p>
				</Info>
				{iconsArr.map((Icon, i) => {
					return (
						<Icon
							style={{ cursor: "pointer" }}
							strokeWidth={3}
							strokeColor={globalStyles.colors.main}
							bgColor={
								cardSectionIndex === i
									? globalStyles.colors.secondary
									: globalStyles.colors.gray
							}
							className="icon"
							key={`icon${i}`}
							onClick={() => {
								setCardSectionIndex(i)
							}}
						/>
					)
				})}
				{isInt ? (
					<YardiIcon
						style={{ cursor: "pointer" }}
						strokeWidth={3}
						strokeColor={null}
						bgColor={globalStyles.colors.main}
						className="icon"
						onClick={() => {
							window.open(currentApt.yardiURL)
							console.log(currentApt)
						}}
					/>
				) : (
					<StarIcon
						style={{ cursor: "pointer" }}
						strokeWidth={3}
						strokeColor={globalStyles.colors.main}
						bgColor={
							favorites.includes(currentApt?.unit)
								? globalStyles.colors.secondary
								: "transparent"
						}
						className="icon"
						onClick={() => {
							const toggled = toggleArrayItem(favorites, currentApt?.unit)
							setFavorites(toggled)
						}}
					/>
				)}
			</LeftCorner>
			<Disclaimer>
				{showBalc.includes(currentApt?.typeName) && (
					<p>Balconies available in select units.</p>
				)}
				<p>
					Renderings are an artist's conception and are intended only as a
					general reference.
				</p>
				<p>
					Feature, materials, finishes and layout of subject unit may be
					different than shown.
				</p>
			</Disclaimer>
		</CardWrapper>
	)
}

const showBalc = ["C06P", "C05P", "C04P", "C03P", "A027P"]

export default ApartmentCard

const Disclaimer = styled.div`
	align-items: center;
	text-align: center;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	font-family: title;
	color: #0000ff;
	font-size: 1.15rem;
	padding: 0.25rem 0;
	p {
		margin: 0.25rem 0;
	}
`

const CardWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	background-color: ${globalStyles.colors.gray};

	.icon {
		height: clamp(20px, 3rem, 500px);
		touch-action: auto;
		pointer-events: auto;
	}
`

const RightCorner = styled.div`
	width: fit-content;
	height: fit-content;
	position: absolute;
	bottom: 0;
	right: 1rem;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	touch-action: none;
	pointer-events: none;
	color: ${globalStyles.colors.white};
`

const LeftCorner = styled.div`
	width: clamp(200px, 20rem, 600px);
	height: clamp(400px, 10rem, 500px);
	position: absolute;
	bottom: 2.5rem;
	left: 1rem;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	gap: 3rem;
	touch-action: none;
	pointer-events: none;
`

const Content = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
`

const Info = styled.div`
	position: absolute;
	bottom: 25%;
	width: 100%;
	font-family: title;
	transition: opacity 0.6s ease-in;
	touch-action: none;
	pointer-events: none;

	& p {
		width: 100%;
		text-align: left;
		font-size: clamp(20px, 2rem, 100px);
		color: ${globalStyles.colors.main};
	}
`
