import { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import gsap from "gsap"

import Gallery from "@components/Gallery"

import globalStyles from "@data/globalStyles"
import { useStore } from "@state/store"

function Story() {
	const [displayGallery, setDisplayGallery] = useState(false)
	const navigating = useStore(s => s.navigating)

	useEffect(() => {
		if (!navigating) {
			gsap.to(".line", {
				opacity: 1,
				delay: 0.2,
				duration: 1,
				stagger: 0.1,
				ease: "power1.inOut",
				onComplete: () => setDisplayGallery(true)
			})
		}
	}, [navigating])

	useEffect(() => {
		return () => setDisplayGallery(false)
	}, [])

	return (
		<Wrapper>
			<StoryCopy>
				<span className="line">CONEY IS. a creative platform that</span>
				<br />
				<span className="line">celebrates the many sides of Coney Island.</span>
				<br />
				<span className="line">Beyond a summer escape, it has a soul </span>
				<br />
				<span className="line">that reveals its true colors when no one</span>
				<br />
				<span className="line">is looking. We’re here to highlight the</span>
				<br />
				<span className="line">people, places, ideas and traditions that</span>
				<br />
				<span className="line">create Coney’s rich cultural tapestry</span>
				<br />
				<span className="line">and eclectic spirit.</span>
			</StoryCopy>
			<Gallery display={displayGallery} />
		</Wrapper>
	)
}

export default Story

const Wrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: ${globalStyles.colors.secondary};
`

const StoryCopy = styled.p`
	font-family: copy;
	width: 90%;
	height: fit-content;
	font-size: clamp(10px, 6rem, 60px);
	text-align: left;
	position: absolute;
	top: 4rem;
	left: 4rem;

	& span {
		opacity: 0;
	}
`
