import globalStyles from "@data/globalStyles"
import { PivotControls, useGLTF } from "@react-three/drei"
import { useStore } from "@state/store"
import { gsap, Sine } from "gsap"
import React, { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { DoubleSide } from "three"

type Props = {}

const UnitsModels = (props: Props) => {
	//@ts-ignore
	const { nodes } = useGLTF("models/units/units.glb")

	const meshArray = Object.values(nodes)

	const filteredUnits = useStore(s => s.filteredUnits)

	const filtedUnitsAsIDArray = React.useMemo(() => {
		return filteredUnits?.map(obj => obj.unit)
	}, [filteredUnits])

	return (
		<group>
			{meshArray.map((meshData: any, i: number) => {
				return (
					<UnitElement
						filtedUnitsAsIDArray={filtedUnitsAsIDArray}
						key={meshData.name}
						data={meshData}
					/>
				)
			})}
		</group>
	)
}

const UnitElement = ({ data, filtedUnitsAsIDArray }) => {
	const { pathname } = useLocation()
	const [hover, setHover] = useState(false)
	const isInt = useStore(s => s.isInt)
	const fullUnits = useStore(s => s.fullUnits)
	const currentApt = useStore(s => s.currentApt)
	const setCurrentApt = useStore(s => s.setCurrentApt)
	const setShowApartmentInfo = useStore(s => s.setShowApartmentInfo)
	const thisApartment = fullUnits?.find(item => item.unit === data.name)
	const isActive = filtedUnitsAsIDArray?.includes(data.name)
	const meshRef = useRef<THREE.Mesh>()
	const matRef = useRef<THREE.MeshMatcapMaterial>()

	function getColor() {
		if (pathname === "/surf") {
			return globalStyles.colors.secondary
		} else {
			return currentApt?.unit === data.name || hover
				? globalStyles.colors.white
				: isActive
				? globalStyles.colors.secondary
				: globalStyles.colors.main
		}
	}

	useEffect(() => {
		if (pathname === "/amenities") {
			meshRef.current &&
				gsap.to(meshRef.current, {
					duration: 0.2,
					visible: false,
					ease: Sine.easeInOut
				})
		} else {
			meshRef.current &&
				gsap.to(meshRef.current, {
					duration: 0.8,
					visible: true,
					ease: Sine.easeInOut
				})
		}
	}, [pathname])
	return (
		<mesh
			ref={meshRef}
			onClick={event => {
				if (pathname === "/residences" || isInt) {
					event.stopPropagation()
					setCurrentApt(thisApartment)
					setShowApartmentInfo(true)
				}
			}}
			onPointerOver={event => {
				event.stopPropagation()
				setHover(true)
			}}
			onPointerLeave={event => {
				event.stopPropagation()
				setHover(false)
			}}
			geometry={data.geometry}>
			<meshMatcapMaterial
				ref={matRef}
				side={DoubleSide}
				key={`filtered${data.meshID}`}
				color={getColor()}
				transparent
			/>
		</mesh>
	)
}

export default UnitsModels
