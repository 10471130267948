import { ContactShadows, PivotControls, useGLTF } from "@react-three/drei"
import React, { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Vector3, DoubleSide } from "three"
import { gsap } from "gsap"

import ConeyLabel from "@canvas_components/ConeyLabel"
import NavCircle from "@components/NavCircle"

import routes from "@routes/routes"

const names = [
	"cyclone",
	"formerparachutejumpride",
	"lunapark_sign",
	// "maimonidespark",
	"billboard",
	"nathans",
	"paulettasteeplechasepier001",
	"train_station",
	"wonder_wheel"
]
const positions = {
	cyclone: new Vector3(-0.5, -0.35, -0.9),
	formerparachutejumpride: new Vector3(-0.3, 0.2, -1.55),
	lunapark_sign: new Vector3(-0, -0.22, -2.3),
	maimonidespark: new Vector3(-1.338286481338841, -0.365078, -0.986524),
	nathans: new Vector3(-1.2, -0.378849, 0.2),
	paulettasteeplechasepier001: new Vector3(
		-3.9681092185920357,
		-0.438319,
		-0.8524950480958302
	),
	train_station: new Vector3(3, -0.43, 4),
	wonder_wheel: new Vector3(-1.02441, 0.03, 1.9),
	billboard: new Vector3(2.6, 2.5, 3)
}

const rotations = {
	cyclone: [0, -0.3, 0],
	train_station: [0, -0.75, 0],
	nathans: [0, 0.3, 0],
	billboard: [0, -0.5, 0],
	lunapark_sign: [0, 0.8, 0]
}
const scale = {
	billboard: 1.6,
	cyclone: 0.67,
	formerparachutejumpride: 1,
	lunapark_sign: 0.5,
	maimonidespark: 1,
	nathans: 1,
	paulettasteeplechasepier001: 1,
	train_station: 1.4,
	wonder_wheel: 1.2
}

const Coney = () => {
	const navigate = useNavigate()
	const { pathname } = useLocation()

	if (pathname === "/surf") {
		return (
			<group>
				{names.map((name, i) => {
					return <ConeyElement modelName={name} key={i} />
				})}
				{routes.map((item, i) => {
					return (
						item.onSurf && (
							<ConeyLabel
								key={i}
								onClick={e => {
									if (pathname === "/surf") {
										e.stopPropagation()
										pathname === "/surf" && navigate(item.path)
									}
								}}
								scale={0.2}
								position={item.position}
								polePosition={item.polePosition}
								htmlLabel={<NavCircle text={item.surfName} />}
							/>
						)
					)
				})}
				<mesh position={[0, -0.5, 0]} rotation-x={-Math.PI / 2} scale={2000}>
					<meshBasicMaterial color="#22408E" side={DoubleSide} />

					<planeGeometry />
				</mesh>
			</group>
		)
	} else return null
}

const ConeyElement = ({ modelName }) => {
	//@ts-ignore
	const { nodes } = useGLTF(`models/coney/${modelName}.glb`)

	// const gRef = React.useRef<THREE.Mesh>()

	return (
		<group scale={scale[modelName]} position={positions[modelName]}>
			{/* <PivotControls
				onDrag={b => {
					const position = new Vector3().setFromMatrixPosition(b)
					const x = positions[modelName].x + position.x
					const y = positions[modelName].y + position.y
					const z = positions[modelName].z + position.z
					console.log(x, y, z)
				}}
				scale={0.5}> */}
			{Object.keys(nodes).map((name, i) => (
				<MeshElement
					nodes={nodes}
					name={name}
					key={`coneyGroup${i}`}
					rotation={rotations[modelName] ?? [0, 0, 0]}
				/>
			))}
			{/* </PivotControls> */}
		</group>
	)
}

const MeshElement = ({ nodes, name, ...props }) => {
	const { pathname } = useLocation()
	const mRef = React.useRef<THREE.MeshMatcapMaterial>()

	useEffect(() => {
		if (pathname === "/surf") {
			gsap.to(mRef.current, {
				duration: 1,
				opacity: 1,
				ease: "power2.out"
			})
		} else {
			gsap.to(mRef.current, {
				duration: 1,
				opacity: 0,
				ease: "power2.out"
			})
		}
	}, [pathname])

	return (
		<mesh geometry={nodes[name].geometry} {...props}>
			<meshMatcapMaterial transparent={true} ref={mRef} color={colours[name]} />
		</mesh>
	)
}

const colours = {
	black: "#504e4e",
	b_1: "#0000FF",
	b_2: "#22408E",
	yellow: "#EBAE15",
	white: "#d1cfcf"
}

export default Coney
