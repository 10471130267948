import { useRef, useEffect, useState } from "react"
export default function useDragScroll(config) {
  const dampFactor = config.damping
  const onDown = config.onDown ?? null
  const onUp = config.onUp ?? null
  const isDown = useRef(false)
  const startX = useRef(null)
  const timeStart = useRef(null)
  const totalTime = useRef(null)
  const movementX = useRef(0)
  const speed = useRef(0)
  const translation = useRef(0)
  const scrollerRef = useRef<HTMLDivElement>()
  const clamp = (v, min, max) => Math.min(Math.max(v, min), max)
  const sliderData = useRef({
    rect: null,
    width: null,
    min: null,
    max: null,
  })
  function handlePointerDown(e) {
    e.preventDefault()
    cancelDamping()
    calculateLimits(scrollerRef.current)
    totalTime.current = null
    isDown.current = true
    if (scrollerRef.current) scrollerRef.current.style.cursor = "grabbing"
    document.addEventListener("pointermove", handlePointerMove)
    if (!("ontouchstart" in window) || !(navigator.maxTouchPoints > 0)) {
      document.addEventListener("pointerleave", handlePointerUp)
    }
    startX.current = e.clientX
    timeStart.current = Date.now()
    onDown && onDown(e)
  }

  function handlePointerMove(e) {
    e.preventDefault()
    if (isDown.current && scrollerRef.current) {
      movementX.current = (startX.current - e.clientX) * -1
      requestAnimationFrame(
        () =>
          (scrollerRef.current.scrollLeft = clamp(
            translation.current - movementX.current,
            sliderData.current.min,
            sliderData.current.max
          ))
      )
    } else {
      return
    }
  }

  let dampFrame
  function startDamping() {
    cancelDamping()
    dampFrame = requestAnimationFrame(handleDamping)
  }

  function handleDamping() {
    translation.current = clamp(
      translation.current - speed.current,
      sliderData.current.min,
      sliderData.current.max
    )
    if (scrollerRef.current) {
      scrollerRef.current.scrollLeft = translation.current
    }
    speed.current *= 0.95
    if (Math.abs(speed.current) > 0.5 && !isDown.current) {
      dampFrame = requestAnimationFrame(handleDamping)
    } else {
      cancelDamping()
    }
  }
  function cancelDamping() {
    cancelAnimationFrame(dampFrame)
  }

  function handlePointerUp(e) {
    e.preventDefault()
    isDown.current = false
    translation.current = clamp(
      translation.current - movementX.current,
      sliderData.current.min,
      sliderData.current.max
    )
    if (scrollerRef.current) scrollerRef.current.style.cursor = "grab"
    totalTime.current = Date.now() - timeStart.current
    speed.current = (movementX.current / totalTime.current) * dampFactor
    startDamping()
    movementX.current = 0
    timeStart.current = null
    onUp && onUp(e)
  }
  function calculateLimits(node) {
    if (node) {
      const rect = node.getBoundingClientRect()
      sliderData.current = {
        rect: rect,
        width: rect.width,
        max: node.scrollWidth - node.clientWidth,
        min: 0,
      }
    }
  }
  useEffect(() => {
    if (scrollerRef.current) {
      scrollerRef.current.style.maxWidth = "100%"
      scrollerRef.current.style.whiteSpace = "nowrap"
      scrollerRef.current.style.overflow = "scroll hidden"
      scrollerRef.current.style.touchAction = "none"
      scrollerRef.current.style.cursor = "grab"
    }
    calculateLimits(scrollerRef.current)
    window.addEventListener("resize", () => {
      calculateLimits(scrollerRef.current)
    })
    return () => {
      document.removeEventListener("pointermove", handlePointerMove)
      document.removeEventListener("pointerleave", handlePointerUp)
      window.removeEventListener("resize", () => {
        calculateLimits(scrollerRef.current)
      })
    }
  }, [])
  return {
    scrollerRef,
    handlePointerDown,
    handlePointerUp
  }
}
